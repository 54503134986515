import React, { useEffect, useRef } from "react";
import { axAppSRCM } from "services/base";
import { lStorage } from "utils/storage";
import { isLoginAuth, setLoginData } from "utils/login";
import { useNavigate } from "react-router-dom";
import { getHomePath } from "utils/common";
import { signOut } from "@heartfulnessinstitute/react-hfn-profile";
import axios from "axios";
import config from "assets/config";

function Oneauth() {
  const navigate = useNavigate();
  const minimalParam = lStorage.get("minimalMode");
  const landingPage = lStorage.get("landingPage");
  const apiCallCount = useRef(0);

  const handleAuth = (status) => {
    document.querySelector("hfn-oneauth").handleProfileAuthentication(status);
  };

  const redirectPath = () => {
    let redirectTo = "";
    if (minimalParam?.authredirect)
      redirectTo = `/${minimalParam?.authredirect}`;
    else if (landingPage?.param?.pathname)
      redirectTo = `${landingPage?.param?.pathname}${landingPage?.param?.search}`;
    else redirectTo = getHomePath();
    lStorage.set("landingPage", {});
    if (isLoginAuth()) navigate(redirectTo);
    else signOut();
  };

  const searchFirebaseId = (firebaseIdVal) => {
    return axios
      .get(`${config.firebaseidSearchBaseUrl}/?firebase_uid=${firebaseIdVal}`)
      .then((res) => {
        const positionTypeCodes = res?.data || [];
        let positionTypeCode = "";
        if (
          positionTypeCodes &&
          positionTypeCodes.length > 0 &&
          Array.isArray(res?.data)
        ) {
          const rfPos = positionTypeCodes.find(
            (code) => code.position_type_code === "RF"
          );
          const zcPos = positionTypeCodes.find(
            (code) => code.position_type_code === "ZC"
          );
          const ccPos = positionTypeCodes.find(
            (code) => code.position_type_code === "CC"
          );
          positionTypeCode =
            rfPos?.position_type_code ||
            zcPos?.position_type_code ||
            ccPos?.position_type_code;
          return positionTypeCode;
        }
        return "";
      })
      .catch(() => {
        handleAuth(false);
      });
  };

  const autoLogin = async (myInfo, srcmToken) => {
    const positionCode = myInfo?.user_firebase_uid
      ? await searchFirebaseId(myInfo?.user_firebase_uid)
      : "";
    if (Object.keys(myInfo).length !== 0) {
      const user = {};
      user.name = myInfo.name;
      user.email = myInfo.user_email;
      const allData = { ...myInfo, ...{ positionCode } };
      user.loginUser = allData;
      user.loginRole = myInfo.abhyas_stage;
      user.srcmToken = srcmToken;
      lStorage.set("authInfo", user);
      setLoginData(user);
      handleAuth(true);
      redirectPath();
    } else {
      handleAuth(false);
    }
  };

  const getProfile = async (accessInfo) => {
    apiCallCount.current += 1;
    if (apiCallCount.current < 4) {
      await axAppSRCM
        .get("/api/v2/me/")
        .then(async (r) => {
          if (r?.data?.results.length > 0) {
            const userInfoData = r?.data?.results[0];
            if (userInfoData?.user_firebase_uid) {
              autoLogin(userInfoData, accessInfo?.access_token);
            } else {
              await getProfile(accessInfo);
            }
          } else {
            handleAuth(false);
          }
        })
        .catch(() => {
          handleAuth(false);
        });
    } else {
      handleAuth(false);
    }
  };

  useEffect(() => {
    document.querySelector("hfn-oneauth").loginCallback = async (res) => {
      try {
        const accessInfo = res?.data;
        if (!accessInfo?.access_token) {
          handleAuth(false);
          return false;
        }
        lStorage.set("authInfo", { srcmToken: accessInfo?.access_token });
        await getProfile(accessInfo);
      } catch {
        handleAuth(false);
      }
      return true;
    };
  }, []);

  return <div />;
}

export default Oneauth;
