import React, { useEffect, useRef } from "react";

// components
// prime components
import { Toast } from "primereact/toast";

// utils 
import toaster from "utils/toaster";
import { useSelector } from "react-redux";

const Toaster = () => {
  const toastProps = useSelector(state => state.toast.props);
  const toast = useRef(null);

  useEffect(() => {
    toaster.ref(toast);
  }, []);

  return (
    <Toast onHide={() => { }} {...toastProps} ref={toast} />
  );
};

export default Toaster;
