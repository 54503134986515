import {
  CLEAR,
  COUNTRY,
  ORGANIZATION,
  COUNTRYORG,
  CENTER,
  ORGCENTER,
  CAMPAIGN,
  ORGCAMPAIGN,
  ROLE,
  USER,
  STATICCOUNTRY,
  STATICCURRENCY,
} from "store/actionTypes/dropdown";

import { RESET } from "store/actionTypes/login";

// constants
import {
  generalStatuses,
  eventStatuses,
  sessionStatuses,
  batchStatuses,
  idTypes,
  emailTempStatuses,
  confirmStatuses,
  availabilityStatuses,
  registrantStatuses,
  templateStatuses,
  emailTargetFilter,
  masterDataStatuses,
  idCardData,
  regParticipants,
  eventStatusesForApprover,
  fieldTypeOptions,
  dynamicFieldTypeOptions,
  conditionOptions,
  genderOptions,
  stayOptions,
  eventActive,
} from "utils/constants";

const initialState = {
  generalStatus: Object.entries(generalStatuses).map((item) => item[1]),
  eventStatus: Object.entries(eventStatuses).map((item) => item[1]),
  eventActiveStatus: Object.entries(eventActive).map((item) => item[1]),
  eventStatusForApprover: Object.entries(eventStatusesForApprover).map(
    (item) => item[1]
  ),
  masterDataStatus: Object.entries(masterDataStatuses).map((item) => item[1]),
  emailTempStatus: Object.entries(emailTempStatuses).map((item) => item[1]),
  confirmStatus: Object.entries(confirmStatuses).map((item) => item[1]),
  idCardData: Object.entries(idCardData).map((item) => item[1]),
  regParticipant: Object.entries(regParticipants).map((item) => item[1]),
  availabilityStatuses: Object.entries(availabilityStatuses).map(
    (item) => item[1]
  ),
  registrantStatuses: Object.entries(registrantStatuses).map((item) => item[1]),
  genderData: Object.entries(genderOptions).map((item) => item[1]),
  stayData: Object.entries(stayOptions).map((item) => item[1]),
  fieldTypeOptions: Object.entries(fieldTypeOptions).map((item) => item[1]),
  dynamicFieldType: Object.entries(dynamicFieldTypeOptions).map(
    (item) => item[1]
  ),
  conditionOptions: Object.entries(conditionOptions).map((item) => item[1]),
  templateStatus: Object.entries(templateStatuses).map((item) => item[1]),
  emailTargetFilter: Object.values(emailTargetFilter),
  sessionStatus: Object.values(sessionStatuses),
  batchStatus: Object.values(batchStatuses),
  country: [],
  organization: [],
  countryOrg: [],
  center: [],
  orgCenter: [],
  campaign: [],
  orgCampaign: [],
  idType: Object.entries(idTypes).map((item) => item[1]),
  role: [],
  user: [],
  staticCountry: [],
  staticCurrency: [],
};

// reducer
const dropdownDetails = (state = initialState, action) => {
  switch (action.type) {
    case RESET:
      return { ...state, ...initialState };
    case CLEAR:
      return { ...state, ...initialState };
    case COUNTRY:
      return { ...state, country: action.payload };
    case ORGANIZATION:
      return { ...state, organization: action.payload };
    case COUNTRYORG:
      return { ...state, countryOrg: action.payload };
    case CENTER:
      return { ...state, center: action.payload };
    case ORGCENTER:
      return { ...state, orgCenter: action.payload };
    case CAMPAIGN:
      return { ...state, campaign: action.payload };
    case ORGCAMPAIGN:
      return { ...state, orgCampaign: action.payload };
    case ROLE:
      return { ...state, role: action.payload };
    case USER:
      return { ...state, user: action.payload };
    case STATICCOUNTRY:
      return { ...state, staticCountry: action.payload };
    case STATICCURRENCY:
      return { ...state, staticCurrency: action.payload };
    default:
      return state;
  }
};

export default dropdownDetails;
